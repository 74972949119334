import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Snackbar,
  Alert,
  AlertTitle,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextareaAutosize,
} from '@mui/material';
import { useUser } from '../components/userContext';

const InstitutionsTable = () => {
  const user = useUser();
  const [institutions, setInstitutions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [successMessage, setSuccessMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [approvingRequestId, setApprovingRequestId] = useState(null);
  const [decliningRequestId, setDecliningRequestId] = useState(null);
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [currentDecliningId, setCurrentDecliningId] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [currentApprovingId, setCurrentApprovingId] = useState(null);

  const fetchInstitutions = async () => {
    const authToken = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://eap.ethernet.edu.et/api/institutions-with-details', {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setInstitutions(data);
      } else {
        console.error('Failed to fetch institutions');
      }
    } catch (error) {
      console.error('Error fetching institutions:', error);
    }
  };

  useEffect(() => {
    fetchInstitutions();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

  const handleApprove = async (institutionId) => {
    const authToken = localStorage.getItem('authToken');
    setApprovingRequestId(institutionId);
    try {
      const response = await fetch(`https://eap.ethernet.edu.et/api/approve-institution/${institutionId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (response.ok) {
        setSuccessMessage('Institution approved successfully.');
        setAlertSeverity('success');
        fetchInstitutions();
      } else {
        setSuccessMessage('Failed to approve institution.');
        setAlertSeverity('error');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setApprovingRequestId(null);
      setConfirmationDialogOpen(false);
    }
  };

  const handleOpenConfirmationDialog = (institutionId) => {
    setCurrentApprovingId(institutionId);
    setConfirmationDialogOpen(true);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const handleOpenDeclineDialog = (institutionId) => {
    setCurrentDecliningId(institutionId);
    setDeclineDialogOpen(true);
  };

  const handleDecline = async () => {
    const authToken = localStorage.getItem('authToken');
    setDecliningRequestId(currentDecliningId);
    setDeclineDialogOpen(false);
    const reason = declineReason;

    try {
      const response = await fetch(`https://eap.ethernet.edu.et/api/decline-institution/${currentDecliningId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reason }),
      });
      if (response.ok) {
        setSuccessMessage('Institution declined successfully.');
        setAlertSeverity('warning');
        fetchInstitutions();
      } else {
        setSuccessMessage('Failed to decline institution.');
        setAlertSeverity('error');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setDecliningRequestId(null);
    }
  };

  const handleCloseDeclineDialog = () => {
    setDeclineDialogOpen(false);
    setDeclineReason('');
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Institution Name</b>
              </TableCell>
              <TableCell align="right">
                <b>Number of Students</b>
              </TableCell>
              <TableCell align="right">
                <b>Number of Departments</b>
              </TableCell>
              {user && user.role === 'eta_admin' && (
                <>
                  <TableCell align="right">
                    <b>Student List</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Actions</b>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {institutions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((institution) => (
                <TableRow key={institution.id}>
                  <TableCell component="th" scope="row">
                    {institution.name}
                  </TableCell>
                  <TableCell align="right">{institution.student_count}</TableCell>
                  <TableCell align="right">{institution.department_count}</TableCell>
                  {user && user.role === 'eta_admin' && (
                    <>
                      <TableCell align="right">
                        <Button variant="contained" color="primary" href={institution.download_link}>
                          Download
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleOpenConfirmationDialog(institution.id)}
                          disabled={approvingRequestId === institution.id}
                          sx={{ mr: 2 }}
                        >
                          {approvingRequestId === institution.id ? <CircularProgress size={24} /> : 'Approve'}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleOpenDeclineDialog(institution.id)}
                          disabled={decliningRequestId === institution.id}
                        >
                          {decliningRequestId === institution.id ? <CircularProgress size={24} /> : 'Decline'}
                        </Button>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={institutions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <Dialog open={confirmationDialogOpen} onClose={handleCloseConfirmationDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Confirm Approval</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to approve this institution?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={() => handleApprove(currentApprovingId)}
            color="success"
            variant="contained"
            disabled={approvingRequestId === currentApprovingId}
          >
            {approvingRequestId === currentApprovingId ? <CircularProgress size={24} /> : 'Proceed'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={declineDialogOpen} onClose={handleCloseDeclineDialog} maxWidth="md" fullWidth>
        <DialogTitle>Decline Institution</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the reason for declining this institution.</DialogContentText>
          <TextareaAutosize
            minRows={3}
            style={{ width: '100%', padding: 8 }}
            placeholder="Enter your reason here..."
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeclineDialog} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleDecline}
            color="secondary"
            variant="contained"
            disabled={decliningRequestId === currentDecliningId}
          >
            {decliningRequestId === currentDecliningId ? <CircularProgress size={24} /> : 'Decline'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
          <AlertTitle>{alertSeverity.charAt(0).toUpperCase() + alertSeverity.slice(1)}</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InstitutionsTable;
