import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Container,
    TablePagination,
} from '@mui/material';

const StudentTable = () => {
    const [students, setStudents] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://ngat.ethernet.edu.et/api/get-all-list', {
                    method: 'GET', // Use POST if required by your endpoint
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setStudents(data); // Assuming data is the full array
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
        fetchData();
    }, []);

    // Handle pagination changes
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Container maxWidth="xl">
            <Paper>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>First Name</b></TableCell>
                                <TableCell><b>Father Name</b></TableCell>
                                <TableCell><b>Grand Father Name</b></TableCell>
                                <TableCell><b>Email</b></TableCell>
                                <TableCell><b>Phone Number</b></TableCell>
                                <TableCell><b>Gender</b></TableCell>
                                <TableCell><b>Program</b></TableCell>
                                <TableCell><b>Reference Number</b></TableCell>
                                <TableCell><b>Exam Center</b></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {students
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((student, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{student.firstName}</TableCell>
                                        <TableCell>{student.fatherName}</TableCell>
                                        <TableCell>{student.grandFatherName}</TableCell>
                                        <TableCell>{student.email}</TableCell>
                                        <TableCell>{student.phoneNumber}</TableCell>
                                        <TableCell>{student.gender}</TableCell>
                                        <TableCell>{student.program}</TableCell>
                                        <TableCell>{student.reference_number}</TableCell>
                                        <TableCell>{student.exam_center_id}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={students.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Container>
    );
};

export default StudentTable;
