import React, { useState, useEffect } from 'react';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, TablePagination } from '@mui/material';

const Dashboard = () => {
  const [programStudentCounts, setProgramStudentCounts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Adjust the number of rows per page as needed

  useEffect(() => {

    const fetchStudentCountsPerProgram = async () => {
      const authToken = localStorage.getItem('authToken');
      
      try {

        const response = await fetch('https://eap.ethernet.edu.et/api/teachers-counts-per-program-inist', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setProgramStudentCounts(data);
        } else {
          console.error('Failed to fetch data');
        }

      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchStudentCountsPerProgram();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container maxWidth="lg" sx={{ ml:0, mt:10,width: 750}} >
      <h4>Number of Teachers per Program</h4>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell><b>Program Name</b></TableCell>
              <TableCell align="right"><b>Number of Students</b></TableCell>
           </TableRow>
          </TableHead>
          <TableBody>
            {programStudentCounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.program}>
                <TableCell component="th" scope="row">
                  {row.topicName}
                </TableCell>
                <TableCell align="right">{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={programStudentCounts.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Container>
  );
};

export default Dashboard;
