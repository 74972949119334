// src/components/Sidebar.js
import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer, Divider, Typography, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import logo from '../moe-logo.png'; // Your logo file here
//import logo from '../moee33.png'; // Your logo file here
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ListItemButton from '@mui/material/ListItemButton';
import StarBorder from '@mui/icons-material/StarBorder';
import Collapse from '@mui/material/Collapse';
import DomainIcon from '@mui/icons-material/Domain';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import BackupIcon from '@mui/icons-material/Backup';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GridViewIcon from '@mui/icons-material/GridView';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useUser } from './userContext';
import DescriptionIcon from '@mui/icons-material/Description';

import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PublicIcon from '@mui/icons-material/Public';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import TaskIcon from '@mui/icons-material/Task';

import SubjectIcon from '@mui/icons-material/Subject';



const Sidebar = () => {

  const user = useUser(); // Access user context


  //console.log('User Email:', user);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const [openteacher, setOpenTeacher] = React.useState(false);

  const handleClickTeacher = () => {
    setOpenTeacher(!openteacher);
  };

  const [openUser, setOpenUser] = React.useState(false);

  const handleClickUser = () => {
    setOpenUser(!openUser);
  };


  const [openSetting, setOpenSetting] = React.useState(false);

  const handleClickSetting = () => {
    setOpenSetting(!openSetting);
  };


  const [openETA, setOpenETA] = React.useState(false);

  const handleClickETA = () => {
    setOpenETA(!openETA);
  };


  const [openReport, setOpenReport] = React.useState(false);

  const handleClickReport = () => {
    setOpenReport(!openReport);
  };

  const [openStudents, setOpenStudents] = React.useState(false);

  const handleClickStudents = () => {
    setOpenStudents(!openStudents);
  };



  return (
    <Drawer
      variant="permanent"
      open={true}
      sx={{
        width: 295,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 300,
          boxSizing: 'border-box',
        },
      }}
    >
      {/* Logo and text container */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '10px',
          backgroundColor: 'primary.main',
          color: 'white',
        }}
      >
        <img src={logo} alt="Logo" height="40" />


        <Typography variant="h7">Student Exit Exam Data Hub</Typography>
      </Box>
      <Divider />

      <List>

        <ListItem button component={Link} to="/home">
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home"></ListItemText>
        </ListItem>
        <Divider />

        {user && (user.role === 'super_admin' || user.role === 'officials' || user.role === 'support_user') && (
          <>
            <Divider />
            <ListItem button component={Link} to="/maindashobard">
              <ListItemIcon><GridViewIcon /></ListItemIcon>
              <ListItemText primary="Dashboard"></ListItemText>
            </ListItem>

            {/* Add more admin specific links here */}
          </>
        )}


        {user && (user.role === 'eta_admin') && (
          <>
            <Divider />

            <ListItem button onClick={handleClickETA}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="ETA"></ListItemText>
              {openETA ? <ExpandLess /> : <ExpandMore />}

            </ListItem>
            <Collapse in={openETA} timeout="auto" unmountOnExit>

              <ListItemButton component={Link} to="/etadashboard" sx={{ pl: 4 }}>
                <ListItemIcon>
                  <SpaceDashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />

              </ListItemButton>



              <ListItemButton component={Link} to="/approvallist" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <PendingActionsIcon />
                </ListItemIcon>
                <ListItemText primary="Pending requests" />

              </ListItemButton>



              <ListItemButton component={Link} to="/validdata" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <TaskIcon />
                </ListItemIcon>
                <ListItemText primary="Validated Data" />




              </ListItemButton>

              <ListItemButton component={Link} to="/rejectedlist" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <TaskIcon />
                </ListItemIcon>
                <ListItemText primary="Rejected List" />




              </ListItemButton>
              <ListItemButton component={Link} to="/uploadETA" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <BackupIcon />
                </ListItemIcon>
                <ListItemText primary="Upload data" />

              </ListItemButton>


            </Collapse>
          </>
        )}



        {user && (user.role === 'super_admin' || user.role === 'eta_approver') && (
          <>
            <Divider />

            <ListItem button onClick={handleClickETA}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="ETA"></ListItemText>
              {openETA ? <ExpandLess /> : <ExpandMore />}

            </ListItem>
            <Collapse in={openETA} timeout="auto" unmountOnExit>

              <ListItemButton component={Link} to="/etadashboard" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <SpaceDashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />

              </ListItemButton>

              <ListItemButton component={Link} to="/assignlist" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <PendingActionsIcon />
                </ListItemIcon>
                <ListItemText primary="Pending for assignment" />

              </ListItemButton>
              <ListItemButton component={Link} to="/ETAadminFinal" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <PendingActionsIcon />
                </ListItemIcon>
                <ListItemText primary="Pending approval list" />

              </ListItemButton>




              <ListItemButton component={Link} to="/validdata" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <TaskIcon />
                </ListItemIcon>
                <ListItemText primary="Validated Data" />


              </ListItemButton>

              <ListItemButton component={Link} to="/rejectedlist" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <TaskIcon />
                </ListItemIcon>
                <ListItemText primary="Rejected List" />




              </ListItemButton>
              <ListItemButton component={Link} to="/uploadETA" sx={{ pl: 4 }}>

                <ListItemIcon>
                  <BackupIcon />
                </ListItemIcon>
                <ListItemText primary="Upload data" />

              </ListItemButton>


            </Collapse>
          </>
        )}




        {user && user.role === 'super_admin' && (
          <>


          </>
        )}



        {user && (user.role === 'super_admin' || user.role === 'officials' || user.role === 'support_user') && (
          <>
            <Divider />

            <ListItem button component={Link} to="/institutions">
              <ListItemIcon><DomainIcon /></ListItemIcon>
              <ListItemText primary="Institutions"></ListItemText>
            </ListItem>

            <ListItem button onClick={handleClickStudents}>
              <ListItemIcon><SchoolSharpIcon /></ListItemIcon>
              <ListItemText primary="Students" />
              {openStudents ? <ExpandLess /> : <ExpandMore />}
            </ListItem>





            <Collapse in={openStudents} timeout="auto" unmountOnExit>

              <ListItemButton component={Link} to="/allstudentlist" sx={{ pl: 4 }}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="List" />

              </ListItemButton>
              <ListItemButton component={Link} to="/publicdata" sx={{ pl: 4 }}>
                <ListItemIcon>
                  <PublicIcon />
                </ListItemIcon>
                <ListItemText primary="Public Instutions Data" />

              </ListItemButton>


              <ListItemButton component={Link} to="/privatedata" sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LockOpenIcon />
                </ListItemIcon>
                <ListItemText primary="Private Instutions Data" />

              </ListItemButton>


            </Collapse>

          </>
        )}

        <Divider />


        {user && user.role === 'university_user' && (
          <>
            <ListItem button onClick={handleClick}>
              <ListItemIcon><SchoolSharpIcon /></ListItemIcon>
              <ListItemText primary="Student" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>


            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/studdasboard" sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <SpaceDashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />

                </ListItemButton>

                <ListItemButton component={Link} to="/upload" sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <BackupIcon />
                  </ListItemIcon>
                  <ListItemText primary="Upload Data" />

                </ListItemButton>

                <ListItemButton component={Link} to="/studentlist" sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <FormatListBulletedSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary="List" />

                </ListItemButton>

                {/* {user && (user.institution_type === 'Public') && (

                  <ListItemButton component={Link} to="/getmycenterstudents" sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FormatListBulletedSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Students at My Center" />

                  </ListItemButton>
                )} */}





              </List>
            </Collapse>
          </>
        )}

{user && user.role === 'university_user' && user.can_upload_teachers=== 1 && (
  <>
    <ListItem button onClick={handleClickTeacher}>
      <ListItemIcon><SchoolSharpIcon /></ListItemIcon>
      <ListItemText primary="Teachers" />
      {openteacher ? <ExpandLess /> : <ExpandMore />}
    </ListItem>

    <Collapse in={openteacher} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton component={Link} to="/teachersdash" sx={{ pl: 4 }}>
          <ListItemIcon>
            <SpaceDashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>

        <ListItemButton component={Link} to="/uploadteacher" sx={{ pl: 4 }}>
          <ListItemIcon>
            <BackupIcon />
          </ListItemIcon>
          <ListItemText primary="Upload Data" />
        </ListItemButton>

        <ListItemButton component={Link} to="/teacherslist" sx={{ pl: 4 }}>
          <ListItemIcon>
            <FormatListBulletedSharpIcon />
          </ListItemIcon>
          <ListItemText primary="List" />
        </ListItemButton>

        {/* {user && user.institution_type === 'Public' && (
          <ListItemButton component={Link} to="/getmycenterstudents" sx={{ pl: 4 }}>
            <ListItemIcon>
              <FormatListBulletedSharpIcon />
            </ListItemIcon>
            <ListItemText primary="Teachers Placed at my center" />
          </ListItemButton>
        )} */}
      </List>
    </Collapse>
  </>
)}





        <ListItem button component={Link} to="/examtopics">
          <ListItemIcon><AssignmentIcon /></ListItemIcon>
          <ListItemText primary="Exam Topics"></ListItemText>
        </ListItem>

        <ListItem button component={Link} to="/examcenters">
          <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
          <ListItemText primary="Exam Centers"></ListItemText>
        </ListItem>

        <Divider />

        {user && (user.role === 'super_admin' || user.role === 'support_user') && (
          <>

            <ListItem button onClick={handleClickUser}>
              <ListItemIcon><PeopleAltIcon /></ListItemIcon>
              <ListItemText primary="Users"></ListItemText>
              {openUser ? <ExpandLess /> : <ExpandMore />}

            </ListItem>


            <Collapse in={openUser} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/registrationrequest" sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <PersonAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Registration Request" />

                </ListItemButton>
                <ListItemButton component={Link} to="/userlist" sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <FormatListBulletedSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary="List" />

                </ListItemButton>


                {user && (user.role === 'super_admin') && (
                  <>
                    <ListItemButton component={Link} to="/rolelist" sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <AccessibilityNewIcon />
                      </ListItemIcon>
                      <ListItemText primary="Roles" />

                    </ListItemButton>



                  </>

                )}





              </List>
            </Collapse>
          </>
        )}

        {/* 
        <ListItem button onClick={handleClickSetting}>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary="Settings"></ListItemText>
          {openUser ? <ExpandLess /> : <ExpandMore />}

        </ListItem>Other list items 


        <Collapse in={openSetting} timeout="auto" unmountOnExit>
          <ListItemButton component={Link} to="/upload" sx={{ pl: 4 }}>
            <ListItemIcon>
              <CorporateFareIcon />
            </ListItemIcon>
            <ListItemText primary="Organization" />

          </ListItemButton>
        </Collapse>

*/}





        {user && (user.role === 'super_admin' || user.role === 'officials' || user.role === 'support_user') && (
          <>
            <ListItem button onClick={handleClickReport}>
              <ListItemIcon><LeaderboardIcon /></ListItemIcon>
              <ListItemText primary="Reports"></ListItemText>
              {openReport ? <ExpandLess /> : <ExpandMore />}

            </ListItem>

          </>
        )}


        <Collapse in={openReport} timeout="auto" unmountOnExit>

          <ListItemButton component={Link} to="/datasubmission" sx={{ pl: 4 }}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Data submission progress" />

          </ListItemButton>
          <ListItemButton component={Link} to="/Re-examregistration" sx={{ pl: 4 }}>


            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Re-exam registration progress" />
          </ListItemButton>

          <ListItemButton component={Link} to="/ngatregistration" sx={{ pl: 4 }}>


            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="NGAT registration progress" />
          </ListItemButton>

          <ListItemButton component={Link} to="/ngatregistrationlist" sx={{ pl: 4 }}>


            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="NGAT registration list" />
          </ListItemButton>




        </Collapse>
        {user && (user.role === 'super_admin') && (
          <>
            <ListItem button component={Link} to="/users-log">
              <ListItemIcon><SubjectIcon /></ListItemIcon>
              <ListItemText primary="User logs"></ListItemText>
            </ListItem>
          </>
        )}
      </List>

    </Drawer >
  );
};

export default Sidebar;
