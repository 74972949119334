import React, { useEffect, useState } from 'react';
import AllList from './Allcandiateslist'; // Assuming ExamTopics component is created as per previous instructions
import Button from '@mui/material/Button';

const Rolespage = () => {
  return (
    <div>
      <h3>All Candidates list</h3>
       <AllList />
    </div>
  );
};

export default Rolespage;
