import React from 'react';
import TeachersTable from './TeachersTable'; // Adjust the import path as needed
import Button from '@mui/material/Button';

const StudentPage = () => {
  return (
    <div>
         <TeachersTable />
    </div>
  );
};

export default StudentPage;
