import React, { useState, useEffect } from 'react';
import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
  Snackbar, Alert, AlertTitle, CircularProgress, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, TextField
} from '@mui/material';
import { useUser } from '../components/userContext';
import { TextareaAutosize } from '@mui/material';

const InstitutionsTable = () => {
  const user = useUser();
  const [institutions, setInstitutions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [successMessage, setSuccessMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [approvingRequestId, setApprovingRequestId] = useState(null);
  const [decliningRequestId, setDecliningRequestId] = useState(null);
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [currentDecliningId, setCurrentDecliningId] = useState(null);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [currentInstitutionId, setCurrentInstitutionId] = useState(null);

  const fetchInstitutions = async () => {
    const authToken = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://eap.ethernet.edu.et/api/institutions-with-details-admin', {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setInstitutions(data);
      } else {
        console.error('Failed to fetch institutions');
      }
    } catch (error) {
      console.error('Error fetching institutions:', error);
    }
  };

  const fetchAdmins = async () => {
    const authToken = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://eap.ethernet.edu.et/api/getetaadmins', {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setAdmins(data);
      } else {
        console.error('Failed to fetch admins');
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const handleOpenAssignDialog = (institutionId) => {
    setCurrentInstitutionId(institutionId);
    setAssignDialogOpen(true);
    fetchAdmins();
  };
  const handleCloseAssignDialog = () => {
    setAssignDialogOpen(false);
    setSelectedAdminId(null);
  };

  useEffect(() => {
    fetchInstitutions();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

  const handleApprove = async (institutionId) => {
    const authToken = localStorage.getItem('authToken');
    setApprovingRequestId(institutionId);
    try {
      const response = await fetch(`https://eap.ethernet.edu.et/api/approve-institution/${institutionId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (response.ok) {
        setSuccessMessage('Institution approved successfully.');
        setAlertSeverity('success');
        fetchInstitutions();
      } else {
        setSuccessMessage('Failed to approve institution.');
        setAlertSeverity('error');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setApprovingRequestId(null);
    }
  };

  const handleOpenDeclineDialog = (institutionId) => {
    setCurrentDecliningId(institutionId);
    setDeclineDialogOpen(true);
  };
  const handleAssignAdmin = async () => {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      setSuccessMessage('Authorization token is missing.');
      setAlertSeverity('error');
      return;
    }

    try {
      // Construct the URL with query parameters
      const url = `https://eap.ethernet.edu.et/api/assign-admin?admin_id=${selectedAdminId}&institution_id=${currentInstitutionId}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setSuccessMessage('Admin assigned successfully.');
        setAlertSeverity('success');
        fetchInstitutions(); // Refresh the list of institutions
        handleCloseAssignDialog(); // Close the dialog box
      } else {
        const errorData = await response.json(); // Parse error message from the server
        setSuccessMessage(errorData.message || 'Failed to assign admin.');
        setAlertSeverity('error');
      }
    } catch (error) {
      console.error('Error assigning admin:', error);
      setSuccessMessage('An unexpected error occurred. Please try again.');
      setAlertSeverity('error');
    }
  };


  const handleDecline = async () => {
    const authToken = localStorage.getItem('authToken');
    setDecliningRequestId(currentDecliningId);
    setDeclineDialogOpen(false);
    const reason = declineReason; // Assume you have this from state

    try {
      const response = await fetch(`https://eap.ethernet.edu.et/api/decline-institution/${currentDecliningId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reason }) // Sending the reason along with the request
      });
      if (response.ok) {
        setSuccessMessage('Institution declined successfully.');
        setAlertSeverity('warning');
        fetchInstitutions();
      } else {
        setSuccessMessage('Failed to decline institution.');
        setAlertSeverity('error');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setDecliningRequestId(null);
    }
  };

  const handleCloseDeclineDialog = () => {
    setDeclineDialogOpen(false);
    setDeclineReason('');
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>Institution Name</b></TableCell>
              <TableCell align="right"><b>Number of Students</b></TableCell>
              <TableCell align="right"><b>Number of Departments</b></TableCell>
              <TableCell align="right"><b>Assigned admin</b></TableCell>
              <TableCell align="right"><b>Review Status</b></TableCell>
              {user && user.role === 'eta_approver' && (
                <>
                  <TableCell align="right"><b>Student List</b></TableCell>
                  <TableCell align="center"><b>Actions</b></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {institutions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((institution) => (
              <TableRow key={institution.id}>
                <TableCell component="th" scope="row">{institution.name}</TableCell>
                <TableCell align="right">{institution.student_count}</TableCell>
                <TableCell align="right">{institution.department_count}</TableCell>
                <TableCell align="right">{institution.admin_full_name || 'No admin assigned'}</TableCell>
                <TableCell align="right">
                  {institution.isdataapproved === 0
                    ? 'On Progress'
                    : institution.isdataapproved === 1
                      ? 'Approved'
                      : institution.isdataapproved === 2
                        ? 'Declined'
                        : 'Unknown'}
                </TableCell>

                {user && user.role === 'eta_approver' && (
                  <>
                    <TableCell align="right">
                      <Button variant="contained" color="primary" href={institution.download_link}>
                        Download
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleOpenAssignDialog(institution.id)}
                        disabled={institution.assigned_admin_id !== 0 || approvingRequestId === institution.id} // Disable if admin is assigned
                        sx={{ mr: 2 }}
                      >
                        {approvingRequestId === institution.id ? <CircularProgress size={24} /> : 'Assign'}
                      </Button>
                     
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={institutions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <Dialog open={declineDialogOpen} onClose={handleCloseDeclineDialog} maxWidth="md" fullWidth>
        <DialogTitle>Decline Institution</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the reason for declining this institution.
          </DialogContentText>
          <TextareaAutosize
            minRows={3}
            style={{ width: '100%', padding: 8 }}
            placeholder="Enter your reason here..."
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeclineDialog}

            variant="contained"
          >Cancel</Button>
          <Button
            onClick={handleDecline}
            color="secondary"
            variant="contained"
            disabled={decliningRequestId === currentDecliningId}
          >
            {decliningRequestId === currentDecliningId ? <CircularProgress size={24} /> : 'Decline'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
          <AlertTitle>{alertSeverity.charAt(0).toUpperCase() + alertSeverity.slice(1)}</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>

      <Dialog open={assignDialogOpen} onClose={handleCloseAssignDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Assign Admin</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select an admin to assign to this institution.
          </DialogContentText>
          <TextField
            select
            fullWidth
            value={selectedAdminId}
            onChange={(e) => setSelectedAdminId(e.target.value)}
            SelectProps={{ native: true }}
            label="Select Admin"
          >
            <option value="">Select Admin</option>
            {admins && admins.length > 0 ? (
              admins.map((admin) => (
                <option key={admin.id} value={admin.id}>
                  {admin.full_name}  {/* Display the full name here */}
                </option>
              ))
            ) : (
              <option value="" disabled>No admins available</option>
            )}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAssignDialog} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleAssignAdmin}
            color="primary"
            variant="contained"
            disabled={!selectedAdminId}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default InstitutionsTable;
