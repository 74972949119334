import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, CircularProgress } from '@mui/material';

const StudentTable = () => {
  const [students, setStudents] = useState([]);
  const [inistname, setInist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setLoading(true);
    const fetchStudents = async () => {
      const authToken = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://eap.ethernet.edu.et/api/getTeachers', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Accept': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setStudents(data.students);
          setInist(data.institution_name);
        } else {
          console.error('Failed to fetch students');
        }
      } catch (error) {
        console.error('Error fetching students:', error);
      }
      setLoading(false);
    };

    fetchStudents();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = () => {
    const csvRows = [];
    const headers = ["Student ID", "Institution Name", "First Name", "Last Name", "Grand father name", "Username", "Password", "Department", "Exam Topic", "Gender", "Enrollment Type", "Exam Center", "Phone Number", "Campus", "Latest GPA", "Admission Year", "Admission Month", "Is Blind", "Is Deaf", "Year", "Total Score"];
    csvRows.push(headers.join(','));

    students.forEach(student => {
      const row = [
        student.student_id,
        inistname,
        student.first_name,
        student.father_name,
        student.grand_father_name,
        student.username,
        student.password,
        student.department,
        student.exam_topic_name,
        student.gender,
        student.enrollment,
        student.exam_center_name,
        student.phone_number,
        student.campus,
        student.latest_gpa,
        student.admission_year,
        student.admission_month,
        student.is_blind,
        student.is_deaf,
        student.year,
        student.total_score,
      ];
      csvRows.push(row.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${inistname} List of Students.csv`;
    link.click();

    URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div>
        <h3>Students of {inistname}</h3>
        <Button variant="contained" onClick={handleDownload} sx={{ mb: 3 }}>
          Download list
        </Button>
        <TableContainer component={Paper}>
          <Table aria-label="student table">
            <TableHead>
              <TableRow>
              <TableCell><b>Username</b></TableCell>
              <TableCell><b>Password</b></TableCell>
              <TableCell><b>ID</b></TableCell>
                <TableCell><b>Institution Name</b></TableCell>
                <TableCell><b>First Name</b></TableCell>
                <TableCell><b>Father's Name</b></TableCell>
                <TableCell><b>Grand Father's Name</b></TableCell>
                <TableCell><b>Gender</b></TableCell>
                <TableCell><b>Enrollment Type</b></TableCell>
                <TableCell><b>Exam Topic</b></TableCell>
                <TableCell><b>Exam Center</b></TableCell>
                <TableCell><b>Total Score</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((student) => (
                <TableRow key={student.id}>
                  <TableCell>{student.username}</TableCell>
                  <TableCell>{student.password}</TableCell>
                  <TableCell>{student.student_id}</TableCell>
                  <TableCell>{inistname}</TableCell>
                  <TableCell>{student.first_name}</TableCell>
                  <TableCell>{student.father_name}</TableCell>
                  <TableCell>{student.grand_father_name}</TableCell>
                  <TableCell>{student.gender}</TableCell>
                  <TableCell>{student.enrollment}</TableCell>                                                                     
                  <TableCell>{student.exam_topic_name}</TableCell>
                  <TableCell>{student.exam_center_name}</TableCell>
                  <TableCell>{student.total_score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={students.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default StudentTable;
