import React, { useState, useEffect } from 'react';
import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
  Snackbar, Alert, AlertTitle, CircularProgress, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, TextField, TextareaAutosize
} from '@mui/material';
import { useUser } from '../components/userContext';

const InstitutionsTable = () => {
  const user = useUser();
  const [institutions, setInstitutions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [successMessage, setSuccessMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [approvingRequestId, setApprovingRequestId] = useState(null);
  const [decliningRequestId, setDecliningRequestId] = useState(null);
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [currentDecliningId, setCurrentDecliningId] = useState(null);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [currentInstitutionId, setCurrentInstitutionId] = useState(null);

  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [currentApprovingId, setCurrentApprovingId] = useState(null);

  const fetchInstitutions = async () => {
    const authToken = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://eap.ethernet.edu.et/api/institutions-with-details-admin-final', {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setInstitutions(data);
      } else {
        console.error('Failed to fetch institutions');
      }
    } catch (error) {
      console.error('Error fetching institutions:', error);
    }
  };

  const fetchAdmins = async () => {
    const authToken = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://eap.ethernet.edu.et/api/getetaadmins', {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setAdmins(data);
      } else {
        console.error('Failed to fetch admins');
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const handleOpenAssignDialog = (institutionId) => {
    setCurrentInstitutionId(institutionId);
    setAssignDialogOpen(true);
    fetchAdmins();
  };
  const handleCloseAssignDialog = () => {
    setAssignDialogOpen(false);
    setSelectedAdminId(null);
  };

  useEffect(() => {
    fetchInstitutions();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
  };

  const handleApprove = async () => {
    const authToken = localStorage.getItem('authToken');
    setApprovingRequestId(currentApprovingId);
    try {
      const response = await fetch(`https://eap.ethernet.edu.et/api/approve-institution-final/${currentApprovingId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (response.ok) {
        setSuccessMessage('Institution approved successfully.');
        setAlertSeverity('success');
        fetchInstitutions();
      } else {
        setSuccessMessage('Failed to approve institution.');
        setAlertSeverity('error');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setApprovingRequestId(null);
      setApproveDialogOpen(false); // Close the modal after approval
    }
  };

  const handleOpenApproveDialog = (institutionId) => {
    setCurrentApprovingId(institutionId);
    setApproveDialogOpen(true);
  };

  const handleCloseApproveDialog = () => {
    setApproveDialogOpen(false);
  };

  const handleOpenDeclineDialog = (institutionId) => {
    setCurrentDecliningId(institutionId);
    setDeclineDialogOpen(true);
  };

  const handleDecline = async () => {
    const authToken = localStorage.getItem('authToken');
    setDecliningRequestId(currentDecliningId);
    setDeclineDialogOpen(false);
    const reason = declineReason; // Assume you have this from state

    try {
      const response = await fetch(`https://eap.ethernet.edu.et/api/decline-institution-final/${currentDecliningId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reason }) // Sending the reason along with the request
      });
      if (response.ok) {
        setSuccessMessage('Institution declined successfully.');
        setAlertSeverity('warning');
        fetchInstitutions();
      } else {
        setSuccessMessage('Failed to decline institution.');
        setAlertSeverity('error');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setDecliningRequestId(null);
    }
  };

  const handleCloseDeclineDialog = () => {
    setDeclineDialogOpen(false);
    setDeclineReason('');
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>Institution Name</b></TableCell>
              <TableCell align="right"><b>Number of Students</b></TableCell>
              <TableCell align="right"><b>Number of Departments</b></TableCell>
              <TableCell align="right"><b>Reviewed by</b></TableCell>
              {user && user.role === 'eta_approver' && (
                <>
                  <TableCell align="right"><b>Student List</b></TableCell>
                  <TableCell align="center"><b>Actions</b></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {institutions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((institution) => (
              <TableRow key={institution.id}>
                <TableCell component="th" scope="row">{institution.name}</TableCell>
                <TableCell align="right">{institution.student_count}</TableCell>
                <TableCell align="right">{institution.department_count}</TableCell>
                <TableCell align="right">{institution.admin_full_name}</TableCell>
                {user && user.role === 'eta_approver' && (
                  <>
                    <TableCell align="right">
                      <Button variant="contained" color="primary" href={institution.download_link}>
                        Download
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleOpenApproveDialog(institution.id)}
                        disabled={approvingRequestId === institution.id}
                        sx={{ mr: 2 }}
                      >
                        {approvingRequestId === institution.id ? <CircularProgress size={24} /> : 'Approve'}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleOpenDeclineDialog(institution.id)}
                        disabled={decliningRequestId === institution.id}
                      >
                        {decliningRequestId === institution.id ? <CircularProgress size={24} /> : 'Decline'}
                      </Button>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={institutions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {/* Decline Dialog */}
      <Dialog open={declineDialogOpen} onClose={handleCloseDeclineDialog} maxWidth="md" fullWidth>
        <DialogTitle>Decline Institution</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the reason for declining this institution.
          </DialogContentText>
          <TextareaAutosize
            minRows={3}
            style={{ width: '100%', padding: 8 }}
            placeholder="Enter your reason here..."
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeclineDialog} variant="contained">Cancel</Button>
          <Button
            onClick={handleDecline}
            color="error"
            variant="contained"
            disabled={declineReason.trim() === ''}
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
      {/* Approve Dialog */}
      <Dialog open={approveDialogOpen} onClose={handleCloseApproveDialog} maxWidth="md" fullWidth>
        <DialogTitle>Confirm Approval</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to approve this institution?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApproveDialog} variant="contained">Cancel</Button>
          <Button
            onClick={handleApprove}
            color="primary"
            variant="contained"
            disabled={approvingRequestId === currentApprovingId} // Prevent multiple clicks
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* Success Snackbar */}
      {successMessage && (
        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
            <AlertTitle>{alertSeverity === 'error' ? 'Error' : 'Success'}</AlertTitle>
            {successMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default InstitutionsTable;
